import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, Row } from 'react-bootstrap'

import MainLayout from '../../components/main-layout'
import Seo from '../../components/seo'
import HeaderBlock from '../../components/header-block'
import ContentCard from '../../components/card'

import { mainRow } from '../../styles/common.module.css'


const AllProjectsPage = ({ data }) => {

    return (
        <MainLayout pageTitle="All Projects">

            <Seo title="All Projects" description="Full portfolio of ai03 Design Studio's projects" />

            <Container>

                <HeaderBlock header="All Projects">
                    <p>Projects are sorted alphabetically.</p>
                </HeaderBlock>

                <Row xs={1} md={2} className={`g-4 ${mainRow}`}>
                    {
                        data.allStrapiBoard.nodes.map(project => (
                            <ContentCard key={project.slug} cardTitle={project.boardname} cardDesc={project.slogan} cardLink={`/projects/${project.slug}`} cardImg={project.cover.localFile} buttonText="View all"></ContentCard>
                        ))
                    }
                </Row>
            </Container>

        </MainLayout>
    )
}

export const query = graphql`
query {
    allStrapiBoard(sort: {fields: boardname, order: ASC}) {
        nodes {
            boardname
            slug
            slogan
            cover {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            aspectRatio: 1.618
                            transformOptions: { fit: COVER }
                            placeholder: BLURRED
                            width: 650
                        )
                    }
                }
            }
        }
    }
}
`

export default AllProjectsPage